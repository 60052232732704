import { apiGet, apiPost } from '~/services/api';
import getRoute from '~/utilities/configs/apiMap';
import type { SuccessTokenResponse } from '~/types/success-token-response';

export const authStore = defineStore('auth', {
  state: () => ({
    isUserAuth: false as Boolean | null,
    userData: null as any,
  }),
  actions: {
    async getUser() {
      const token = localStorage.getItem('acc_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const endpoint = getRoute({ endpont: 'get_user' });
      const refreshEndpoint = getRoute({ endpont: 'post_refresh_token' });
      try {
        const response = await apiGet({
          url: endpoint,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //get user by access token
        console.log('response in get user', response?.status.value);
        if (response?.status) {
          const isSuccess = response?.status.value === 'success';
          if (isSuccess) {
            console.log(
              'User is get successfully. Userdata: ',
              response?.data.value,
            );
            if (!response?.data.value) return;
            this.userData = response.data.value;
            this.isUserAuth = true;
          } else {
            console.log('Get user failed');
            //if get user by access token failed, try to refresh token
            const refreshResponse = await apiPost({
              url: refreshEndpoint,
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            });

            if (refreshResponse?.status) {
              const isSuccess =
                refreshResponse?.status >= 200 && refreshResponse.status < 300;
              if (isSuccess) {
                console.log(
                  'Tokens are refreshed successfully. AccessToken: ',
                  refreshResponse?.data.value,
                );
                if (!refreshResponse?.data.value) return;
                localStorage.setItem(
                  'acc_token',
                  (refreshResponse.data.value as SuccessTokenResponse)
                    .accessToken,
                );
                localStorage.setItem(
                  'refresh_token',
                  (refreshResponse.data.value as SuccessTokenResponse)
                    .refreshToken,
                );
                //try to get user again with new access token
                const secondTryResponse = await apiGet({
                  url: endpoint,
                  headers: {
                    Authorization: `Bearer ${
                      (refreshResponse.data.value as SuccessTokenResponse)
                        .accessToken
                    }`,
                  },
                });

                if (secondTryResponse?.status) {
                  const isSuccess = response?.status.value === 'success';
                  if (isSuccess) {
                    console.log(
                      'User is get successfully. Userdata: ',
                      response?.data.value,
                    );
                    if (!response?.data.value) return;
                    this.userData = response.data.value;
                    this.isUserAuth = true;
                  } else {
                    console.log('Second get user failed');
                  }
                }
              } else {
              }
            }
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
});
